@import url(https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap);
body {
  margin: 0;
  font-family: 'Bebas Neue', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html{
  height: 100%;
}
.toggle-button{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button-line {
    width: 30px;
    height: 2px;
    background: white;
}
.navbar{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #666;
    height: 56px;
    z-index: 100;
}

.navbar__logo{
    margin-left: 1rem;
    font-weight: 900;
}

.navbar__logo h2{
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin-top: 10px;
}

.spacer{
    flex: 1 1;
}

.navbar__nav{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.navbar_items ul{
    list-style: none;
    margin: 4px 0 0 25px;
    padding: 0;
    display: flex;
    

}

.navbar_items li{
    padding: 0 0.5rem;
}

.navbar_items a{
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.navbar_items a:hover,
.navbar_items a:active{
    color: #fa923f;
}

.navbar__logo a:hover,
.navbar__logo a:active{
    color: #fa923f;
}

@media (max-width: 768px){
    .navbar_items{
        display: none;
    }
}

@media (min-width: 769px){
    .navbar__button{
        display: none;
    }

    .navbar__logo{
        margin-left: 0;
    }
}
.slide{
    height: 100%;
    background-color: white;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    max-width: 300px;
    z-index: 300;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.4s ease-out;
    transition: -webkit-transform 0.4s ease-out;
    transition: transform 0.4s ease-out;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
}

.slide.open{
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.slide ul{
    margin-top: 35px;
    list-style: none;
    display: flex;
    flex-direction: column;
}

.slide li{
    margin-bottom: 10px;
}

.slide a {
    color: #521751;
    text-decoration: none;
    font-size: 1.2rem;
    cursor: pointer;
}

.slide a:hover,
.slide a:active{
    color: #fa923f;
}

@media (min-width: 769px){
    .slide{
        display: none;
    }
}
.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 100;
    left: 0;
    top: 0;
}
.centerMe{
    background-image: url(/static/media/sand.ce870232.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 22px;
    height: 100vh;
}

.centerContent{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.centerContent h1{
    color: white;
    border: black solid 1px;
    background-color: black;
    padding: 2px 7px 0 7px;
    border-radius: 3px;
    cursor: default;
}

.centerList{
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
}

.centerList li{
    margin-right: 15px;
}
#projects{
    /* height: 100vh; */
    background-color: #C3CCCB;
}

#projects h2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.card-body{
    position: relative;
}

.btn{
    position: absolute;
    bottom: 35px;
    left: 121px;
    /* right: 121px; */
}
.card{
    width: 20rem;
    height: 595px;
}

.card:hover{
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
    -webkit-transition: box-shadow 0.35s ease;
    transition: box-shadow 0.35s ease;
}

.card-body{
    padding: 3rem 0 !important;
}

.card-text{
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
}

.container-fluid .row{
    padding: 2.5rem 0;
}

.overflow{
    overflow: hidden;
}

.card-img-top{
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 0.35s ease;
    transition: -webkit-transform 0.35s ease;
    transition: transform 0.35s ease;
    transition: transform 0.35s ease, -webkit-transform 0.35s ease;
    cursor: pointer;
    
}

.card-img-top:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

@media (max-width: 768px){
    .card{
        margin: 0 auto 10px auto;
    }
    
}
#contact{
    height: 100vh;
    background-color: #D1B99B;
}

#contact form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}


.footer{   
    width: 100%;
    bottom: 0;
    left: 0;
    background: #666;
    height: 65px;
}

.footer-icons li{
    list-style-type: none;
    float: right;
    margin-right: 35px;
    margin-top: 7px;
}

.footer-icons{
    float: left;
    margin-top: 7px;
}


main{
  margin-top: 56px;
}
