.centerMe{
    background-image: url("../../images/sand.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 22px;
    height: 100vh;
}

.centerContent{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.centerContent h1{
    color: white;
    border: black solid 1px;
    background-color: black;
    padding: 2px 7px 0 7px;
    border-radius: 3px;
    cursor: default;
}

.centerList{
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
}

.centerList li{
    margin-right: 15px;
}