#projects{
    /* height: 100vh; */
    background-color: #C3CCCB;
}

#projects h2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.card-body{
    position: relative;
}

.btn{
    position: absolute;
    bottom: 35px;
    left: 121px;
    /* right: 121px; */
}