.card{
    width: 20rem;
    height: 595px;
}

.card:hover{
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
    transition: box-shadow 0.35s ease;
}

.card-body{
    padding: 3rem 0 !important;
}

.card-text{
    font-size: 0.9rem;
    padding: 0.4rem 1.9rem;
}

.container-fluid .row{
    padding: 2.5rem 0;
}

.overflow{
    overflow: hidden;
}

.card-img-top{
    transform: scale(1);
    transition: transform 0.35s ease;
    cursor: pointer;
    
}

.card-img-top:hover{
    transform: scale(1.05);
}

@media (max-width: 768px){
    .card{
        margin: 0 auto 10px auto;
    }
    
}