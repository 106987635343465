#contact{
    height: 100vh;
    background-color: #D1B99B;
}

#contact form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

