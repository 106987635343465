.slide{
    height: 100%;
    background-color: white;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    max-width: 300px;
    z-index: 300;
    transform: translateX(-100%);
    transition: transform 0.4s ease-out;
}

.slide.open{
    transform: translateX(0);
}

.slide ul{
    margin-top: 35px;
    list-style: none;
    display: flex;
    flex-direction: column;
}

.slide li{
    margin-bottom: 10px;
}

.slide a {
    color: #521751;
    text-decoration: none;
    font-size: 1.2rem;
    cursor: pointer;
}

.slide a:hover,
.slide a:active{
    color: #fa923f;
}

@media (min-width: 769px){
    .slide{
        display: none;
    }
}